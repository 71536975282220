:root {
  --font-family-base: Open Sans, sans-serif;
  --font-family-heading: Monaspace Argon, sans-serif;
  --font-family-monospace: Monaspace Argon, monospace;
  --font-family-logo: Open Sans, sans-serif;

  --color-primary-bg: #5ab983;
  --color-primary-bg-2: #86caa0;
  --color-primary-bg-3: #86caa0;
  --color-primary-text: #f9fcfa;
  --color-base-bg: #f9fcfa;
  --color-base-bg-2: #ecf6ef;
  --color-base-bg-3: #d1eada;
  --color-base-text: #2d2e32;
  --color-base-text-2: #696a6e;
  --color-header-text:  #111827;
  
  --logo-text-font-size: 28px;
  --logo-text-font-weight: 500;

  --menu-font-family: Open Sans, sans-serif;
  --menu-font-size: ;
  --menu-text-transform: "Capitalize";
  --menu-font-weight: 400;

  --header-height: 60px;
  --section-padding: 80px;
  --card-border-radius: 5px;
  --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
  --color-primary-bg: #5ab983;
  --color-primary-bg-2: #86caa0;
  --color-primary-bg-3: #86caa0;
  --color-primary-text: #f9fcfa;
  --color-base-bg: #2d2e32;
  --color-base-bg-2: #4a4b4f;
  --color-base-bg-3: #696a6e;
  --color-base-text: #fafbfe;
  --color-base-text-2: #f2f3f8;
  --color-logo-text: #fafbfe;
  --color-header-text: #F4F4F5;
}

@import 'style';