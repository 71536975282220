.logo-desktop {
  .logo-image {
    margin-right: 6px;
  }
}
.title {
  .title-heading {
    h1 {
      font-weight: 500;
      strong {
        font-weight: 500;
        text-decoration: underline;
        text-decoration-thickness: 4px;
        text-decoration-color: var(--color-primary-bg);
      }
    }
  }
  .title-description {
    p {
      font-weight: 300;
      font-size: 26px;
      line-height: 1.32;
      letter-spacing: 0px;
    }
  }
}

.menu-main {
  display: none;
  color: var(--color-menu-text);
  font-family: var(--font-family-base);
  font-size: 16px;
  letter-spacing: 0;
  @include media-breakpoint-up(md) {
    display: block;
  }
  ul {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      height: inherit;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      &:last-of-type {
        margin: 0;
      }
      > a {
        height: inherit;
        font-weight: 400;
        text-transform: capitalize;
        display: block;
        padding: 10px 10px 10px 10px;
        text-decoration: none;
        color: inherit;
        border-radius: 6px;
        &:hover {
          text-decoration: none;
          background-color: var(--color-base-bg-2);
          i {
            color: var(--color-primary-bg);
          }
        }
        @include media-breakpoint-up(lg) {
          padding: 10px 13px 10px 13px;
        }
        i {
          font-size: 14px;
        }
      }
    }
    li.active {
      > a {
        font-weight: inherit;
        text-decoration: underline;
        text-decoration-color: var(--color-primary-bg);
        text-decoration-thickness: 2px;
      }
    }
    li.menu-item-dropdown {
      position: relative;
      &:hover {
        .dropdown-menu {
          transition: top 0.25s ease 0s;
          opacity: 1;
          top: 42px;
          right: -163px;
          pointer-events: auto;
        }
      }
      .dropdown-menu {
        top: 54px;
        right: -163px;
        transition: top 0.25s ease 0s;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        border-radius: 6px;
        width: 300px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        z-index: 20;
        background-color: var(--color-base-bg);
        border: 1px solid var(--color-base-bg-2);
        box-shadow: rgba(17, 17, 17, 0.1) 0px 20px 25px -5px, rgba(17, 17, 17, 0.04) 0px 10px 10px -5px;
        html[data-bs-theme="dark"] & {
          box-shadow: rgba(127, 127, 127, 0.25) 0px 25px 50px -12px;
        }
        .dropdown-item {
          display: flex;
          padding: 12px;
          border-radius: 6px;
          &.active {
            .dropdown-item-content {
              .dropdown-item-name {
                text-decoration: underline;
                text-decoration-color: var(--color-primary-bg);
                text-decoration-thickness: 2px;
              }
            }
          }
          &:hover {
            background-color: var(--color-base-bg-2);
            text-decoration: none;
            color: inherit;
          }
          .dropdown-item-icon {
            display: block;
            margin-right: 8px;
            img {
              width: 32px;
              height: 32px;
            }
            html[data-bs-theme="dark"] & {
              display: none;
            }
            &.icon-invert {
              display: none;
              html[data-bs-theme="dark"] & {
                display: block;
              }
            }
          }
          .dropdown-item-content {
            .dropdown-item-name {
              font-size: 14px;
              line-height: 1.22;
              font-weight: 500;
              color: var(--color-base-text);
            }
            .dropdown-item-description {
              font-size: 12px;
              font-weight: 400;
              color: var(--color-base-text-2);
            }
          }
        }
      }
    }
  }
}

.grid-footer {
  .grid-view-more-button {
    padding: 10px 14px;
    font-size: 16px;
    background: var(--color-primary-bg);
    border-radius: 40px;
    text-transform: capitalize;
    font-weight: normal;
    letter-spacing: 0;
    color: var(--color-primary-text);
    text-decoration: none;
    transition: all 0.15s ease;
  }
}

.page-post {
  .post-categories {
    display: inline-flex;
    font-size: 12px;
    font-family: var(--font-family-base);
    a {
      color: var(--color-base-text);
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      background-color: var(--color-base-bg-2);
      &:hover {
        text-decoration: none;
        background-color: var(--color-base-bg-3);
      }
    }
  }

  .post-categories-more {
    display: flex;
    align-items: baseline;
    font-size: 24px;
    font-family: var(--font-family-base);
    a {
      color: var(--color-base-text);
      padding: 6px 12px;
      border-radius: 3px;
      margin-left: 10px;
      background-color: var(--color-base-bg-2);
      &:hover {
        text-decoration: none;
        background-color: var(--color-base-bg-3);
      }
    }
  }
}
.page-post-1 {
  .post-header {
    .post-date {
      display: inline-block;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-base-text);
    }
    .post-authors {
      display: flex;
      flex-direction: row;
      .author {
        margin-right: 16px;
      }
    }
    .post-title {
      margin-top: 10px;
      h1 {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
    .post-description {
      p {
        color: var(--color-base-text-2);
        margin-bottom: 20px;
        font-size: 22px;
      }
    }
    .post-image {
      margin-top: 40px;
      img {
        margin-top: 20px;
        max-width: 100%;
        height: auto;
        border-radius: var(--card-border-radius);
      }
    }
  }
}
.page-post-2 {
  .post-header {
    .post-image {
      margin-top: 0px;
      img {
        max-width: 100%;
        height: auto;
        border-radius: var(--card-border-radius);
      }
    }
    .post-title {
      margin-top: 40px;
      h1 {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
    .post-description {
      p {
        color: var(--color-base-text-2);
        margin-bottom: 20px;
        font-size: 22px;
      }
    }
    .post-date {
      display: inline-block;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-base-text);
    }
    .post-authors {
      margin-top: 20px;
    }
  }
}
.page-post-3 {
  .post-header {
    .post-image {
      margin-top: 0;
      margin-bottom: 40px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
      img {
        max-width: 100%;
        height: auto;
        border-radius: var(--card-border-radius);
      }
    }
    .post-title {
      margin-top: 0;

      h1 {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
    .post-description {
      p {
        color: var(--color-base-text-2);
        margin-bottom: 20px;
        font-size: 22px;
      }
    }
    .post-date {
      display: inline-block;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-base-text);
    }
    .post-authors {
      margin-top: 20px;
    }
  }
}

.page-category {
  .title {
    .title-badge {
      font-family: var(--font-family-base);
    }
  }
}

.card-post {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  .card-thumbnail {
    border-radius: var(--card-border-radius);
    overflow: hidden;
    display: inline-flex;
    a {
      display: inline-flex;
    }
    img {
      aspect-ratio: 16 / 9;
      width: 100%;
      object-fit: contain;
      &:hover {
        opacity: 0.8;
      }
    }
    &.card-thumbnail-large {
      @include media-breakpoint-up(md) {
        max-height: 260px;
      }
      @include media-breakpoint-up(xl) {
        max-height: 300px;
      }
    }
  }
  .card-content {
    padding-top: 30px;
    padding: 15px;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .card-title {
    margin-bottom: 10px;
    h3 {
      font-weight: 400;
      line-height: 1.36;
      font-size: 21px;
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-description {
    margin: 10px 0 10px 0;
    p {
      line-height: 1.46;
      color: var(--color-base-text-2);
      margin: 0;
      font-size: 15px;
      font-weight: 300;
      overflow-y: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
  .card-title + .card-description {
    margin-top: 0;
  }
  .card-date {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-base-text-2);
    font-weight: 300;
    font-size: 13px;
    line-height: 1.3;
  }
  .card-categories {
    display: inline-flex;
    font-size: 12px;
    font-family: var(--font-family-base);
    a {
      color: var(--color-base-text-2);
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      background-color: var(--color-base-bg-2);
    }
  }
  .card-image {
    width: 60px;
    margin-bottom: 10px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .card-meta {
    display: flex;
    margin-bottom: 10px;
    .on {
      margin: 0 4px 0 4px;
    }
  }
  .card-authors {
    .author {
      .author-image {
        img {
          height: 26px;
          width: 26px;
        }
      }
      .author-content {
        flex-direction: column;
        .author-name {
          font-weight: 500;
          line-height: 1.2;
          font-size: 12px;
        }
        .author-date {
          font-size: 12px;
          line-height: 1.2;
        }
      }
    }
  }
  .card-readmore {
    margin-top: auto;
    line-height: 1;
    i {
      margin-left: 5px;
      margin-top: 4px;
    }
  }
  // Tweaks for dark mode
  html[data-bs-theme="dark"] & {
  }
}
.card-post.card-row {
  border-radius: 0;
  box-shadow: none;
  overflow: visible;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .card-thumbnail {
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0;
      margin-bottom: 10px;
      width: 340px;
      margin-right: 30px;
      margin-bottom: 0;
      a {
        background-color: var(--color-base-2);
      }
    }
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
.card-post.card-list {
  border-radius: 0;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .card-thumbnail {
    padding: 0;
    margin-bottom: 10px;
    margin-right: 16px;
    margin-bottom: 0;
    width: 80px;
    img {
      height: auto;
      object-fit: cover;
    }
  }
  .card-meta {
    margin-bottom: 2px;
  }
  .card-title {
    margin-bottom: 4px;
    font-weight: 600;
    h3 {
      font-size: 20px;
      line-height: 1.3;
      margin: 0;
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
  .card-authors {
    display: none;
  }
  .card-description {
    display: none;
  }
  .card-categories {
    display: none;
  }
}
.card-post.card-full {
  .card-thumbnail {
    @include media-breakpoint-up(md) {
      min-height: auto;
      padding: 0;
      margin-bottom: 10px;
      width: auto;
      max-height: none;
      margin-right: 0;
      margin-bottom: 30px;
      a {
        background-color: var(--color-base-200);
      }
    }
  }
  .card-content {
  }
  .card-categories {
    margin-top: 20px;
  }
  .card-meta {
    margin-top: 20px;
    order: 4;
  }
  .card-title {
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.4;
    flex-basis: 50%;
    h3 {
      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }
    }
    a {
      color: var(--color-base-text);
    }
  }
  .card-description {
    flex-basis: 40%;
  }
  .card-content {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}

.post-solution {
  margin-top: 40px;
}

.solution-link-button {
  padding: 10px 14px;
  font-size: 16px;
  background: var(--color-primary-bg);
  border-radius: 40px;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0;
  color: var(--color-primary-text);
  text-decoration: none;
  transition: all 0.15s ease;
}


/* pagefind styles */

body {
  --pagefind-ui-font: "Open Sans", sans-serif;
}

html[data-bs-theme="dark"] {
  --pagefind-ui-primary: #f9fcfa;
  --pagefind-ui-text: #fafbfe;
  --pagefind-ui-background: #2d2e32;
  --pagefind-ui-border: #4a4b4f;
  --pagefind-ui-tag: #4a4b4f;
}
